import React from "react";
import Marketo from "../marketo";
import {getEditorColors, isItUndefined, modifyLinks, renderTextColor} from "../../util/DataUtil";

const RegisterLeadMarketo = (data) => {
    const emphasizedTextColor = getEditorColors(isItUndefined(data?.children?.field_marketo_main_title), isItUndefined(data?.children?.field_marketo_main_description)).filter(x => x)[0];
    const isItRegisterLead = isItUndefined(data.children.field_marketo_variant) === 'RegisterLead';
    return (
        <div id={"register-lead"} className={`${isItRegisterLead ? 'register-lead' : 'facility-marketo-block'}`}>
            <div className={'container inner-container'}>
               {!isItRegisterLead ? <div className={'left'}>
                    {isItUndefined(data?.children?.field_marketo_main_title) ? <h1 className={`${renderTextColor(data?.regionColor)} ${emphasizedTextColor ? emphasizedTextColor : ''}`} dangerouslySetInnerHTML={{ __html: modifyLinks(isItUndefined(data?.children?.field_marketo_main_title))}}/> : null}
                    {isItUndefined(data?.children?.field_marketo_main_description) ? <div className={`${renderTextColor ? emphasizedTextColor : ''}`} dangerouslySetInnerHTML={{ __html: modifyLinks(isItUndefined(data?.children?.field_marketo_main_description))}}/> : null}
                </div> : null}
                <div className={`form-right ${!isItRegisterLead ? 'facility-marketo' : ''}`}>
                    <Marketo globalWidth={data?.width} lang={data?.lang} global={data?.global}>{{...data?.children, lang : data?.lang}}</Marketo>
                </div>
            </div>
        </div>
    );
}

export default RegisterLeadMarketo;