import React, {useState} from "react";

import { getColor, getRegionColor } from "./ColorUtil";
import dynamic from "next/dynamic";
import TemplateQuote from "../components/template/template-quote";
import TemplateSupergraphic3D from "../components/template/TemplateSupergraphic3D";
import ContentImage from "../components/individual-product-template/content-image";
import TemplateTwoColumnStats from "../components/template/template-two-columns-stats";
import { isItUndefined, returnPrimaries, unixToDateString} from "./DataUtil";
import TemplateStatsRight from "../components/template/TemplateStatsRight";
import ContentImageRight from "../components/product-family-template/content-image-right";
import PromoCtaWithForm from "../components/promoCtaWithForm";
import TemplateContactInfo from "../components/template/TemplateContactInfo";
import TemplateTable from "../components/template/TemplateTable";
import { useSelector } from "react-redux";
import TempalteFeaturedContent from "../components/template/TemplateFeaturedContent";
import RegisterLeadMarketo from "../components/facility/register-lead";

const ProductFamily = dynamic(() => import( "../components/product-family-template/product-family"));
const TemplateRelated = dynamic(() => import('../components/template/template-related'));
const BlockTitle = dynamic(() => import('../components/base/block-title'));
const BlogIntro = dynamic(() => import('../components/blog/blog-intro'));
const Consultation = dynamic(() => import('../components/consultation'));
const TemplateBanner = dynamic(() => import('../components/template/TemplateBanner'));
const TemplateTitle = dynamic(() => import('../components/template/TemplateTitle'));
const TemplateText = dynamic(() => import('../components/template/TemplateText'));
const TemplateItems = dynamic(() => import('../components/template/TemplateItems'));
const TemplateVideo = dynamic(() => import('../components/template/TemplateVideo'));
const TemplateMarketo = dynamic(() => import('../components/template/TemplateMarketo'));
const TemplateLead = dynamic(() => import('../components/template/template-lead'));
const TemplateDownload = dynamic(() => import('../components/template/template-download'));
const TemplateLogo = dynamic(() => import('../components/template/template-logo'));
const TemplateImage = dynamic(() => import('../components/template/template-image'));
const FacilityTable = dynamic(() => import('../components/facility/facility-table'));
const FacilityRegionList = dynamic(() => import('../components/facility/facility-region-list'));
const FacilityAccordionBlock = dynamic(() => import('../components/facility/accordion/facility-accordion-block'));
const FacilityCtaBar = dynamic(() => import('../components/facility/facility-cta-bar'));
const FacilityMarketo = dynamic(() => import('../components/facility/facility-marketo'));
const TemplateHero = dynamic(() => import('../components/template/TemplateHero'));
const Partnership = dynamic(() => import('../components/data-centre/partnership'));
const TemplateHeader = dynamic(() => import('../components/template/TemplateHeader'));
const TemplateStats = dynamic(() => import('../components/template/TemplateStats'));
const TemplateProduct = dynamic(() => import('../components/template/TemplateProduct'));
const WhyBuild = dynamic(() => import('../components/facility/why-build'));
const TemplateGlobalMap = dynamic(() => import('../components/template/TemplateGlobalMap'));
const Tags = dynamic(() => import('../components/base/tags'));
const TemplatePopular = dynamic(() => import('../components/template/template-popular'));
const TemplateMetroMap = dynamic(() => import('../components/template/TemplateMetroMap'));
const TemplateMetroMapV2 = dynamic(() => import('../components/template/TemplateMetroMapv2'));
const TemplateRegionalMap = dynamic(() => import('../components/template/TemplateRegionalMap'));
const CustomerWhySuperGraphic = dynamic(() => import('../components/individual-product-template/customer-why-supergraphic'));
const NumberList = dynamic(() => import('../components/individual-product-template/number-list'));
const WhyChoose = dynamic(() => import('../components/individual-product-template/why-choose'));
const HorizontalNumericalStepper = dynamic(() => import('../components/individual-product-template/horizontal-numerical-stepper'));
const GraphicHero = dynamic(() => import('../components/individual-product-template/graphic-hero'));
const Icon = dynamic(() => import('../components/product-family-template/icon'));
const FullWidthImageBanner = dynamic(() => import('../components/product-family-template/full-width-image-banner'));
const ContentImageLeft = dynamic(() => import('../components/product-family-template/content-image-left'));
const Awards = dynamic(() => import('../components/product-family-template/awards'));
const Testimonial = dynamic(() => import('../components/product-family-template/testimonial'));
const Feature = dynamic(() => import('../components/product-family-template/feature'));
const CardStepper = dynamic(() => import('../components/product-family-template/card-stepper'));
const DataPromo = dynamic(() => import('../components/product-family-template/data-promo'));
const Card = dynamic(() => import('../components/about/card'));
const CompanyLocationsInfo = dynamic(() => import('../components/about/company-locations-info'));
const PressReleasesLatest = dynamic(() => import('../components/press-releases/press-releases-latest'));
const LeadershipBlock = dynamic(() => import('../components/leadership/LeadershipBlock'));
const AuthorBlock = dynamic(() => import('../components/template/AuthorBlock'));
const ChartBlock = dynamic(() => import('../components/ChartBlock'));
// import { ConditionalBlock } from "../components/ConditionalBlock";

function getComponentForBlock({
    block, 
    key, 
    category, 
    alias, 
    lang, 
    color, 
    read, 
    global, 
    setItemCount, 
    itemCount, 
    facilityData, 
    facilityAcordionData, 
    regionColor, 
    width, 
    contentType,
    blockType, 
    metroData, 
    regionData, 
    popular_downloads_posts, 
    related_content,
    tags,
    locationsData, 
    dataSet, 
    individualPageForceBackgroundColor, 
    setIsSearchActive, 
    productsData, 
    categories, 
    locations, 
    content_type, 
    allMetros, 
    metaTags,
    timestamp,
    dataCenters,    
}){    
    switch (block?.type){        
        case 'company_locations_info_block_typ':
            return <CompanyLocationsInfo width={width} lang={lang} global={global} data={block.data} key={key}/>
        case 'card_block_type':
            return <Card lang={lang} global={global} data={block.data} key={key}/>
        case 'data_promo_block':
            return <DataPromo lang={lang} global={global} data={block.data} key={key}/>
        case 'card_stepper_block_type':
            return <CardStepper lang={lang} global={global} data={block.data} key={key}/>
        case 'feature_block_type':
            return <Feature width={width} lang={lang} global={global} data={block.data} key={key}/>
        case 'testimonial_block_type':
            return <Testimonial lang={lang} global={global} data={block.data} key={key}/>
        case 'awards_block_type':
            return <Awards lang={lang} global={global} data={block.data} key={key}/>
        case 'content_image_left_block_type':
            return <ContentImageLeft lang={lang} global={global} data={block.data} key={key}/>
        case 'full_width_image_banner':
            return <FullWidthImageBanner data={block.data} key={key} lang={lang}/>
        case 'icon_block':
            return <Icon width={width} data={block.data} key={key}/>
        case 'content_image_block':
            return <ContentImage lang={lang} global={global} data={block.data} key={key}/>
        case 'horizontal_numerical_stepper_blo':
            return <HorizontalNumericalStepper lang={lang} global={global} data={block.data} key={key}/>
        case 'number_list_block_type':
            return <NumberList width={width} global={global} data={block.data} key={key}/>
        case 'why_choose':
            return <WhyChoose width={width} data={block.data} key={key}/>
        case 'customer_why_supergraphic':
            return <CustomerWhySuperGraphic individualPageForceBackgroundColor={individualPageForceBackgroundColor} data={block.data} key={key}/>
        case 'title_block_type':
            return <BlockTitle data={block.data} key={key}/>
        case 'intro_block_type':
            return <BlogIntro key={key}>{block.data}</BlogIntro>
        case 'consultation_block_type':
            return <Consultation key={key}>{block.data}</Consultation>
        case 'template_banner_block_type':
            return <TemplateBanner key={key} metaTags={metaTags} >{block.data}</TemplateBanner>
        case 'template_title_block_type':
            return <TemplateTitle datetime={timestamp} blockType={blockType} global={global} read={read} color={color} key={key} contentType={contentType} category={category} data={block.data} alias={alias} lang={lang} itemCount={itemCount}/>
        case 'template_text_block_type':
            return <TemplateText color={color} itemCount={itemCount} key={key}>{block.data}</TemplateText>
        case 'quote_block_type':
            return <TemplateQuote color={color} itemCount={itemCount} key={key}>{block.data}</TemplateQuote>
        case 'title_subtitle_paragraph_block_t':
            return <TemplateItems itemCount={itemCount} setItemCount={setItemCount} key={key}>{block.data}</TemplateItems>
        case 'template_media_block_type':
            return <TemplateVideo width={width} key={key}>{block.data}</TemplateVideo>
        case 'marketo_form_block_type':
            return <TemplateMarketo lang={lang} global={global} itemCount={itemCount} key={key}>{block.data}</TemplateMarketo>
        case 'template_lead_block':
            return <TemplateLead color={color} key={key} data={block.data} />
        case 'download_button_block':
            return <TemplateDownload lang={lang} alias={alias} key={key} data={block.data} />
        case 'template_logo_block':
            return <TemplateLogo key={key}>{block.data}</TemplateLogo>
        case 'template_image_block_type':
            return <TemplateImage lang={lang} global={global} key={key}>{block.data}</TemplateImage>
        case 'facility_specifications_table':
            return <FacilityTable width={width} facilityData={facilityData} lang={lang} key={key}>{block.data}</FacilityTable>
        case 'region_list_block_type':
            return <FacilityRegionList locationsData={locationsData} key={key}>{block.data}</FacilityRegionList>
        case 'facility_accordion_block_type':
            return <FacilityAccordionBlock lang={lang} global={global} width={width} regionColor={regionColor} facilityAcordionData={facilityAcordionData} key={key}>{block.data}</FacilityAccordionBlock>
        case 'cta_bar_block_type':
            return <FacilityCtaBar lang={lang} global={global} facilityData={facilityData} width={width} regionColor={regionColor} key={key}>{block.data}</FacilityCtaBar>
        case 'marketo_form_facility_block_type':
            // if(isItUndefined(block.data.field_marketo_form_ids) && ((isItUndefined(block.data.field_marketo_form_ids) === "2926") || isItUndefined(block.data.field_marketo_form_ids) === "2813")){
                return <RegisterLeadMarketo width={width} global={global} regionColor={regionColor} lang={lang} key={key}>{block.data}</RegisterLeadMarketo>
            // }
            // return <FacilityMarketo global={global} regionColor={regionColor} lang={lang} key={key}>{block.data}</FacilityMarketo>
        case 'hero_block':
            return <TemplateHero lang={lang} global={global} width={width} metroData={metroData} facilityData={facilityData} regionColor={regionColor} key={key}>{block.data}</TemplateHero>
        case 'partnership_block_type':
            return <Partnership width={width} facilityData={facilityData} regionColor={regionColor} key={key}>{block.data}</Partnership>
        case 'header_block_type':
            return <TemplateHeader global={global} width={width} dataSet={dataSet} regionColor={regionColor} key={key}>{block.data}</TemplateHeader>
        case 'stats_block_type':
            return <TemplateStats key={key} regionData={regionData} metroData={metroData} facilityData={facilityData} regionColor={regionColor}>{block.data}</TemplateStats>
        case 'product_block_type':
            return <TemplateProduct key={key} regionData={regionData} metroData={metroData} facilityData={facilityData} regionColor={regionColor}>{block.data}</TemplateProduct>
        case 'why_build_block_type':
            return <WhyBuild width={width} regionData={regionData} metroData={metroData} facilityData={facilityData} key={key} regionColor={regionColor}>{block.data}</WhyBuild>
        case 'related_content_block_type':
            if(!block?.data?.field_related_content_item_limit[0]){
                return ''
            }

            if (block?.data?.field_related_content_type[0]?.value === '1'){
                if (typeof block?.data?.field_related_manual_content === 'undefined'){
                    return ''
                }

                // let posts = block?.data?.field_related_manual_content[0] ? block?.data?.field_related_manual_content[0] : popular_downloads_posts;
                // let posts = block?.data?.field_related_manual_content.length !== 0 ? block?.data?.field_related_manual_content : popular_downloads_posts;

                let posts = getPosts(block?.data?.field_related_manual_content, popular_downloads_posts, isItUndefined(block?.data?.field_related_content_item_limit));

                if (!Array.isArray(posts)){
                    posts = [posts]
                }

                if (!posts || (posts?.length === 0)){
                    return <div className={'test'}></div>
                }

                return <TemplatePopular
                    title={block?.data?.field_popular_downloads_title[0]?.value}
                    posts={posts}
                    width={width}
                />
            }else{
                return <TemplateRelated
                    primaries={returnPrimaries(related_content)}
                    link={block?.data?.field_link}
                    key={key}
                    tags={tags}
                    data={related_content}
                    title={block?.data?.field_popular_downloads_title[0]?.value}
                />
            }
        case 'global_map':
            return <TemplateGlobalMap global={global} lang={lang} setIsSearchActive={setIsSearchActive} width={width} locationsData={locationsData} dataCenters={dataCenters} >{block.data}</TemplateGlobalMap>
        case 'regional_map_block_type':
            return <TemplateRegionalMap global={global} lang={lang} allMetros={allMetros} width={width} regionData={regionData} dataCenters={dataCenters}>{block.data}</TemplateRegionalMap>
        case 'metro_map_block_type':
            // return <TemplateMetroMap lang={lang} global={global} width={width} metroData={metroData} allMetros={allMetros}>{block.data}</TemplateMetroMap>
            return <TemplateMetroMapV2 lang={lang} global={global} width={width} metroData={metroData} allMetros={allMetros}>{block.data}</TemplateMetroMapV2>
        case 'hero_graphic_block_type':
            return <GraphicHero key={key} individualPageForceBackgroundColor={individualPageForceBackgroundColor} width={width}>{block.data}</GraphicHero>
        case 'supergraphic_3d_block_type':
            return <TemplateSupergraphic3D facilityData={facilityData} regionData={regionData} locationsData={locationsData} productsData={productsData} lang={lang} global={global} regionColor={regionColor} metroData={metroData}>{block.data}</TemplateSupergraphic3D>
        case 'two_column_stats_block_type':
            return <TemplateTwoColumnStats global={global} lang={lang}>{block.data}</TemplateTwoColumnStats>
        case 'product_family_block_type':
            return <ProductFamily global={global} lang={lang} categories={categories} locations={locations}>{block.data}</ProductFamily>
        case 'stats_right_block_type':
            return <TemplateStatsRight lang={lang} global={global} contentType={content_type} key={key} regionData={regionData} metroData={metroData} facilityData={facilityData} regionColor={regionColor}>{block.data}</TemplateStatsRight>
        case 'content_image_right_block_type':
            return <ContentImageRight global={global} lang={lang}>{block.data}</ContentImageRight>
        case 'promo_cta_with_form_block_type':
            return <PromoCtaWithForm global={global} lang={lang}>{block.data}</PromoCtaWithForm>
        case 'contact_info_block_type':
            return <TemplateContactInfo categories={categories} locations={locations} global={global} lang={lang}>{block.data}</TemplateContactInfo>
        case 'table_block_type':
            return <TemplateTable sizeOfScreen={width}  contentType={contentType} lang={lang} global={global}>{block.data}</TemplateTable>
        case 'featured_content_block_type':
            return <TempalteFeaturedContent lang={lang} global={global}>{block.data}</TempalteFeaturedContent>
        case 'press_releases_block_type':
            return <PressReleasesLatest sizeOfScreen={width} lang={lang}>{block.data}</PressReleasesLatest>
        case 'leadership_block_type':
            return <LeadershipBlock data={block.data} width={width} />
        case 'author_block':
            return <AuthorBlock data={block.data} width={width} />
        // case 'basic':
        //     return <ChartBlock data={block.data.field_chart[0]}/>
        // case 'conditional_block':
        //     return <ConditionalBlock data={block.data}/>            
        default :
            return <></>
        }
}

function getBlockOrder(blocks){
    let order = [];

    if (!blocks){
        return []
    }

    blocks.forEach(item => {
        order.push(item[Object.keys(item)[0]].id)
    })

    return order
}

export function renderIndividualProductTemplateBlocks({data,width,lang,global, categories, locations, popular_downloads_posts, related_content, tags}){
    let key = 'field_template_blocks';

    let blockOrder = getBlockOrder(data?.field_template_blocks);
    let blocksReal = data?.field_template_blocks;
    let productsData = data;
    let category = data?.field_template_category;

    if (blockOrder.length === 0){
        key = 'field_blog_blocks';
        blockOrder = getBlockOrder(data?.field_blog_blocks);
        blocksReal = data?.field_blog_blocks;
    }

    if (blockOrder.length === 0){
        return ''
    }

    const blocks = [];

    blocksReal.forEach((item, index)=> {
        let key = Object.keys(item)[0]
        let itemSend = item[key]

        blocks.push({
            data : itemSend,
            type : key
        })
    })

    let send = [];

    let individualPageForceBackgroundColor = false;

    for(let i = 0; i < blockOrder.length; i++) {

        for (let k = 0; k < blocks.length; k++) {
            if (blocks[k].data === '' || !blocks[k].data) {
                continue
            }

            if (!blocks[k].data || (blockOrder[i] !== blocks[k].data.id)) {
                continue
            }
            
            if (blocks[k].type === 'template_media_block_type'){
                send.push(<div key={i} className="inner-container">{getComponentForBlock({block:blocks[k], key:i, category, alias:data['url-alias'], lang, width:width})}</div>)
                continue
            }

            if (blocks[k].type === 'related_content_block_type'){
                send.push(getComponentForBlock({block:blocks[k], key : i, category, alias:data['url-alias'], lang, color:getColor(blocks), global, regionColor: getRegionColor(data), width:width, popular_downloads_posts : popular_downloads_posts, related_content : related_content, tags : tags}))
                continue
            }

            send.push(getComponentForBlock({block:blocks[k], key : i, width, individualPageForceBackgroundColor, lang, global, productsData, categories, locations, metaTags:data?.field_meta_tags}))
        }
    }

    return send;
}

export function renderProductFamilyTemplate({data,width,lang,global, categories, locations, popular_downloads_posts, related_content, tags}){
    let blockOrder = getBlockOrder(data.field_template_blocks);
    let blocksReal = data.field_template_blocks;
    let category = data.field_template_category;

    if (blockOrder.length === 0){
        blockOrder = getBlockOrder(data.field_blog_blocks);
        blocksReal = data.field_blog_blocks;
    }

    if (blockOrder.length === 0){
        return ''
    }

    const blocks = [];

    blocksReal.forEach((item, index)=> {
        let key = Object.keys(item)[0]
        let itemSend = item[key]

        blocks.push({
            data : itemSend,
            type : key
        })
    })

    let send = [];

    for(let i = 0; i < blockOrder.length; i++) {

        for (let k = 0; k < blocks.length; k++) {
            if (blocks[k].data === '' || !blocks[k].data) {
                continue
            }

            if (!blocks[k].data || (blockOrder[i] !== blocks[k].data.id)) {
                continue
            }

            if (blocks[k].type === 'related_content_block_type'){
                send.push(getComponentForBlock({block:blocks[k], key : i, category, alias:data['url-alias'], lang, color:getColor(blocks), global, regionColor: getRegionColor(data), width:width, popular_downloads_posts : popular_downloads_posts, related_content : related_content, tags : tags}))
                continue
            }

            send.push(getComponentForBlock({block:blocks[k], i, width, lang, global, categories, locations, metaTags:data?.field_meta_tags}))
        }
    }

    return send
}

export function renderBlocks(data, width, lang, global, popular_downloads_posts, related_content, tags){
    const [itemCount, setItemCount] = useState(0);
    const docWidth = useSelector((state) => state.pageSlice.screenSize);

    let key = 'field_template_blocks';

    let blockOrder = getBlockOrder(data.field_template_blocks);
    let blocksReal = data.field_template_blocks;

    let timestamp = unixToDateString(isItUndefined(data.field_press_release_date));

    if (blockOrder.length === 0){
        key = 'field_blog_blocks';
        blockOrder = getBlockOrder(data.field_blog_blocks);
        blocksReal = data.field_blog_blocks;
    }

    if (blockOrder.length === 0){
        return ''
    }

    const blocks = [];

    blocksReal.forEach((item, index)=> {
        let key = Object.keys(item)[0]
        let itemSend = item[key]

        blocks.push({
            data : itemSend,
            type : key
        })
    })

    let category = data.field_template_category;
    const contentType = data?.content_type;
    const blockType = data?.field_content_type_templat;

    let send = [];
    let rememberMarketo = []
    let rememberRelated = []
    let rememberBanner = []
    let rememberTitle = []
    let rememberLead = []
    let rememberImage = []
    let rememberAuthorBlock = []
    let hasRelated = false;
    
    let isMarketo = false;
    let isPromo = false;
    
    for(let i = 0; i < blockOrder.length; i++){

        for(let k = 0; k < blocks.length; k++){
            if (blocks[k].data === '' || !blocks[k].data){
                continue
            }

            if (!blocks[k].data || (blockOrder[i] !== blocks[k].data.id)){
                continue
            }

            if(blocks[k].type === 'template_image_block_type'){
                isPromo = true;
            }

            if (blocks[k].type === 'marketo_form_block_type'){
                isMarketo = true;
                // docWidth >= 1220 ?
                //     rememberMarketo = getComponentForBlock({block: blocks[k], key:i, global, category, alias:data['url-alias'], lang, itemCount}) :
                //     send.push(getComponentForBlock({block: blocks[k], key:i, global, category, alias:data['url-alias'], lang, itemCount}))


                // rememberMarketo = getComponentForBlock({block: blocks[k], key:i, global, category, alias:data['url-alias'], lang, itemCount})

                if(docWidth === 0){
                    //return;
                    continue;
                }

                if(docWidth >= 1220){
                    rememberMarketo = getComponentForBlock({block: blocks[k], key:i, global, category, alias:data['url-alias'], lang, itemCount})
                }

                if(docWidth < 1220){
                    // send.push(getComponentForBlock({block:blocks[k], i, global, category, alias:data['url-alias'], lang, color:getColor(blocks), setItemCount, itemCount}))
                    send.push(getComponentForBlock({block: blocks[k], key:i, global, category, alias:data['url-alias'], lang, itemCount}))
                }

                continue
            }

            if (blocks[k].type === 'template_banner_block_type'){
                rememberBanner = getComponentForBlock({block:blocks[k], key:i, category, alias:data['url-alias'], lang, metaTags:data?.field_meta_tags})
                continue
            }

            if (blocks[k].type === 'template_title_block_type'){
                rememberTitle = getComponentForBlock({block:blocks[k], key:i, category, alias:data['url-alias'], lang, color:getColor(blocks), read:data.field_time_to_read, global, itemCount, contentType, blockType, timestamp})
                continue
            }

            if (blocks[k].type === 'template_image_block_type'){
                rememberImage = getComponentForBlock({block:blocks[k], key:i, category, alias:data['url-alias'], lang})
                continue
            }

            if (blocks[k].type === 'template_lead_block' && blocks[k].data.field_display_block[0].value === '1'){
                rememberLead = getComponentForBlock({block:blocks[k], key:i, category, alias:data['url-alias'], lang, color:getColor(blocks)})
                continue
            }

            if (blocks[k].type === 'related_content_block_type'){
                hasRelated = true
                send.push(<Tags tags={tags} />)
                rememberRelated = getComponentForBlock({block:blocks[k], key : i, category, alias:data['url-alias'], lang, color:getColor(blocks), global, regionColor: getRegionColor(data), width:width, popular_downloads_posts : popular_downloads_posts, related_content : related_content, tags : tags})
                continue
            }

            if(blocks[k].type === 'author_block'){
                rememberAuthorBlock = getComponentForBlock({block:blocks[k], key:i, category, alias:data['url-alias'], lang})
                continue
            }

            send.push(getComponentForBlock({block:blocks[k], i, category, alias:data['url-alias'], lang, color:getColor(blocks), setItemCount, itemCount, contentType, blockType, metaTags: data?.field_meta_tags}))
            break;
        }
    }

    if (!hasRelated){
        send.push(<Tags tags={tags} />)
    }

    if (docWidth >= 1220 && (rememberMarketo || rememberBanner || rememberTitle || rememberLead)){
        return <div className={'template-container'}>
            {rememberBanner}
            <div className={'inner-container'}>
                {rememberTitle}
                <div className={`inner-vertical ${!isMarketo && !isPromo ? 'full-width' : ''}`}>
                    <div className={'vertical-items'}>
                        {rememberLead}
                        {send}
                    </div>
                    {rememberImage ? <div className={'right'}>
                        {rememberImage}
                    </div> : <></>}
                    {/* {
                        typeof rememberMarketo == 'object' && rememberMarketo.length !== 0 &&
                        <div className={'form-right'}>
                            {rememberMarketo}
                            {rememberAuthorBlock}
                        </div>
                    } */}

                    <div className={'form-right'}>
                        {typeof rememberMarketo === 'object' && rememberMarketo.length !== 0 ? (
                            <>
                                {rememberMarketo}
                                {rememberAuthorBlock}
                            </>
                        ) : (
                            rememberAuthorBlock
                        )}
                    </div>

                </div>
            </div>
            {rememberRelated}
        </div>
    }else if (docWidth < 1220 && (rememberBanner || rememberTitle || rememberLead)){
        return <div className={'template-container'}>
            {rememberBanner}
            <div className={'inner-container'}>
                {rememberTitle}
                <div className={'inner-vertical'}>
                    <div className={'vertical-items'}>
                        {rememberLead}
                        {send}
                        {rememberImage}
                    </div>
                </div>
                {rememberAuthorBlock}
                {rememberRelated}
            </div>
        </div>
    }

    return send;
}

export function renderDataCentreBlocks({data, lang, width, global, popular_downloads_posts, related_content, tags, setIsSearchActive, metros, dataCenters}){
    let blockOrder = getBlockOrder(data?.field_template_blocks);
    let blocksReal = data?.field_template_blocks;
    let facilityData = {};
    let facilityAcordionData = {};
    let metroData = {};
    let regionData = {};
    let locationsData = {};    
    
    if (blockOrder.length === 0){
        return ''
    }

    let send = [];
    let category = data.field_template_category;
    const blocks = [];

    
    if(data.content_type === 'location'){
        facilityData = {
            ...data,
            field_sales_contact_location: data?.field_sales_contact_location[0]?.value,
            field_sales_support_location: data?.field_sales_support_location[0]?.value,
            field_building_structure: data?.field_building_structure[0]?.value,
            field_cooling_plant_redundancy: data?.field_cooling_plant_redundancy[0]?.value,
            // field_generator_capacity: data?.field_generator_capacity[0]?.value ? `${data.field_generator_capacity[0]?.value} kW`: undefined,
            field_total_building_size: isItUndefined(data?.field_total_building_size_in_ft2) ? `${isItUndefined(data?.field_total_building_size_in_ft2)} ft² (${isItUndefined(data.field_total_building_size_in_m2)} m²)` : isItUndefined(data.field_total_building_size_in_m2) ? `${isItUndefined(data.field_total_building_size_in_m2)} m²` : undefined,
            field_ups_redundancy: data?.field_ups_redundancy[0]?.value,
            field_utility_power_capacity: data?.field_utility_power_capacity[0]?.value ? `${data?.field_utility_power_capacity[0]?.value} kW` : undefined,
            // max_power_density: isItUndefined(data?.field_max_power_density) ? `${isItUndefined(data?.field_max_power_density)} watts/ft²` : undefined,
            metroName: data?.metro,
            siteCodeName: data?.field_site_code_location[0]?.value,
            nickName: data?.field_nick[0]?.value,
            address: data?.field_address_location[0]?.value,
            lat: data?.field_latitude[0]?.value,
            lng: data?.field_longitude[0]?.value,
            field_design_pue: isItUndefined(data?.field_design_pue),
            field_carbon_free_energy: `${isItUndefined(data?.field_carbon_free_energy)}%`,
            field_renewable_energy: `${isItUndefined(data?.field_renewable_energy)}%`,
            // field_ups_power_capacity: `${isItUndefined(data?.field_ups_power_capacity)} kW`,

        }

        facilityAcordionData = {
            ...data,
        }
    }
    else if(data.content_type === 'metro'){
        metroData = {
            ...data,
            field_region_name: data?.locations?.region,
            metroName: data?.field_metro_name[0]?.name

        }
    }else if(data.content_type === 'region'){
        regionData = {
            ...data,
        }
    }else if(data.content_type === 'global_data_centre_locations'){
        locationsData = {
            ...data,            
        }
    }
    else if(data.content_type === 'global_data_centre_locations'){
        metroData = {
            ...data
        }
    }

    let dataSet = {...facilityData, ...metroData, ...locationsData, ...regionData}

    blocksReal.forEach((item, index)=> {
        let key = Object.keys(item)[0]
        let itemSend = item[key]

        blocks.push({
            data : itemSend,
            type : key
        })
    })

    for(let i = 0; i < blockOrder.length; i++){

        for(let k = 0; k < blocks.length; k++){
            if (blocks[k].data === '' || !blocks[k].data){
                continue
            }

            if (!blocks[k].data || (blockOrder[i] !== blocks[k].data.id)){
                continue
            }

            if (blocks[k].type === 'template_media_block_type'){
                send.push(<div key={i} className="inner-container">{getComponentForBlock({block:blocks[k], key:i, category, alias:data['url-alias'], lang, width:width})}</div>)
                continue
            }

            if (blocks[k].type === 'related_content_block_type'){
                send.push(getComponentForBlock({block:blocks[k], key : i, category, alias:data['url-alias'], lang, color:getColor(blocks), facilityData, facilityAcordionData, global, regionColor: getRegionColor(data), width:width, metroData, popular_downloads_posts : popular_downloads_posts, related_content : related_content, tags : tags}))
                continue
            }

            send.push(getComponentForBlock({block:blocks[k], key : i, category, alias:data['url-alias'], lang, color:getColor(blocks), facilityData, facilityAcordionData, global, regionColor: getRegionColor(data), width:width, metroData, regionData, locationsData, dataSet, setIsSearchActive, content_type:data.content_type, allMetros:metros, metaTags:data?.field_meta_tags, dataCenters:dataCenters}))
            break;
        }
    }

    let docWidth = 0;

    if (typeof document !== 'undefined'){
        docWidth = document.getElementsByTagName('main')[0]?.offsetWidth
    }
    return send;
}

function getPosts(manualPosts, popularDownloads, limit){
    const manual = manualPosts.slice();

    if (manual.length < limit) {
        const remainingItems = limit - manual.length;
        const popular = checkAndPushUniqueItems(manualPosts, popularDownloads)
        const itemsToAdd = popular.slice(0, remainingItems);

        manual.push(...itemsToAdd);
    }


    return manual;
}

function checkAndPushUniqueItems(manual, popular) {
    let results = [];

    popular.filter(item => {
        return !manual.some(obj => JSON.stringify(obj) === JSON.stringify(item));
    }).forEach(item => {
        if (!results.some(obj => JSON.stringify(obj) === JSON.stringify(item))) {
            results.push(item);
        }
    });

    return results;
}